import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { ShareService } from './share/share.service';
import { MenuController } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  routeLink : string = '';
  splash : boolean  = true;
  poweredBy : boolean = true;

  showSubMenu : boolean = false


  constructor(private location: Location,private share_ : ShareService,private menu : MenuController) {
    setTimeout(()=>{                         
      this.splash = false;
  }, 2000);
  }

  onActivate(event : any) {
    if(this.routeLink == "" || '/about' || '/contact'){
      this.poweredBy = false;
    }
  }

  navigate(link : string){
    this.share_.router.navigate([link])
    this.menu.close();
  }


  clearData(){
    this.share_.clearData();
  }

  ngAfterContentChecked() {
    this.routeLink = this.location.path();
   
  }

  goRoute(route){
    this.share_.router.navigate([route])
    if(this.routeLink.includes('clinic')){
      this.share_.router.navigate(['/clinic'] , {queryParamsHandling : 'merge' })
      window.location.reload();
    }
  }
  

}


