import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AlertController } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { LoadingController } from '@ionic/angular';
import { NavController } from '@ionic/angular';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})



export class ShareService {


  private REST_API_SERVER = environment.apiServer;
  isLoading = false;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public menuController: MenuController,
    public modalController: ModalController,
    private httpClient: HttpClient,
    public alertController: AlertController,
    public toastController: ToastController,
    public loadingController: LoadingController,
    public navCtrl: NavController,
  ) {
   
  }

 
  public getRequestWithParams(url: string, params: HttpParams) {
    return this.httpClient.get(this.REST_API_SERVER + url, { params: params });
  }

  public getRequest(url: string) {
    return this.httpClient.get(this.REST_API_SERVER + url);
  }

  public postRequest(url: string, data) {
    return this.httpClient.post(this.REST_API_SERVER + url, data)
  }

  public postRequestParam(url: string) {
    return this.httpClient.post(this.REST_API_SERVER + url, null)
  }

  public putRequest(url: string, data) {
    return this.httpClient.put(this.REST_API_SERVER + url, data)
  }

  public putRequestParam(url: string) {
    return this.httpClient.put(this.REST_API_SERVER + url, null)
  }

  public deleteRequest(url: string, data) {
    return this.httpClient.delete(this.REST_API_SERVER + url, data)
  }

  public deleteRequestParam(url: string,) {
    return this.httpClient.delete(this.REST_API_SERVER + url)
  }

  public clearData(){
    localStorage.removeItem("survey_type");
    localStorage.removeItem("survey");
    localStorage.removeItem("reg_current_survey");
    localStorage.removeItem("format_survey");
    localStorage.removeItem("regular_survey");
    localStorage.removeItem("user_type");
    localStorage.removeItem("current_survey");
    localStorage.removeItem("intro-survey");
    localStorage.removeItem("result");
    localStorage.removeItem("service_type");
    localStorage.removeItem("regular_selected_survey");
    localStorage.removeItem("result-data");
    localStorage.removeItem("userData");
    localStorage.removeItem("save_survey");
  }




  async presentLoading() {
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: '',
      duration: 2000
    });
    await loading.present();

    const { role, data } = await loading.onDidDismiss();
    console.log('Loading dismissed!');
  }


  async presentToast(message, duration, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: duration,
      color: color
    });
    toast.present();
  }

  async presentAlert(header, subtitle, message) {
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: header,
        subHeader: subtitle,
        message: `<p>` + message + `</p>`,
      });
  
      await alert.present();
    }

  // async presentAlert(header, subtitle, message, button) {
  //   const alert = await this.alertController.create({
  //     // cssClass: 'my-custom-class',
  //     header: header,
  //     subHeader: subtitle,
  //     message: `<p>` + message + `</p>`,
  //     buttons: [button]
  //   });

  //   await alert.present();
  // }



  // async presentErrorAlert(header, subtitle, message, button) {
  //   const alert = await this.alertController.create({
  //     // cssClass: 'my-custom-class',
  //     header: header,
  //     subHeader: subtitle,
  //     message: `<img src="assets/overcl.png" class="error-img"/><p>` + message + `</p>`,
  //     buttons: [button]
  //   });

  //   await alert.present();
  // }

  // async presentLoading() {
  //   this.isLoading = true;
  //   return await this.loadingController.create({
  //     message: 'Please wait ...',
  //     duration: 3000
  //   }).then(a => {
  //     a.present().then(() => {
  //       console.log('loading presented');
  //       if (!this.isLoading) {
  //         a.dismiss().then(() => console.log('abort laoding'));
  //       }
  //     });
  //   });
  // }

  // async loadingDismiss() {
  //   this.isLoading = false;
  //   return await this.loadingController.dismiss().then(() => console.log('loading dismissed'));
  // }

  

}
