import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ShareService } from '../../share.service';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  routeLink : string ;

  constructor(private location: Location,private share_ : ShareService) { }

  ngOnInit() {
    
  }

  ngAfterContentChecked() {
    this.routeLink = this.location.path();
  }

  navigate(link : string){
    this.share_.router.navigate([link])

  }




  clearData(){
    this.share_.clearData();
  }

}
