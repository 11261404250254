import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SplashScreenComponent } from './elements/splash-screen/splash-screen.component';
import { QRCodeModule } from 'angularx-qrcode';
import { IonicSelectableModule } from 'ionic-selectable';
import { MenuComponent } from './elements/menu/menu.component';
import { NgxCaptureModule } from 'ngx-capture';
import { SafePipe } from '../pipe/safe.pipe';

@NgModule({
  declarations: [
    SplashScreenComponent,
     MenuComponent,
     SafePipe
  ],
  imports: [
    CommonModule,
    IonicSelectableModule ,
    QRCodeModule,
    
  ],
  exports: [
    SplashScreenComponent,
    MenuComponent,
    IonicSelectableModule ,
    QRCodeModule,
    SafePipe
  ]
})
export class ShareModule { }
