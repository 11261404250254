// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // defaultLoadingImage : "assets/image/loading-image.gif",
  // errorImage : "assets/image/no-image.png",
  // apiServer : "http://localhost:3000/",
  //apiServer : "http://43.228.125.24:1999/Modules/Webservices/WebService_Public_CPI.asmx/"
  apiServer: "http://satecha.org:503/Modules/Webservices/WebService_Public_CPI.asmx/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
