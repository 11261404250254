import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ShareModule } from './share/share.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from './share/httpconfig.interceptor';
// import { SafePipe } from './pipe/safe.pipe';

var animated  = true;
var innerWidth = window.innerWidth
if(innerWidth > 768){
  animated = false
}else{
  animated = true
}

@NgModule({
  declarations: [
    AppComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(
      {
        backButtonText: 'ရှေ့စာမျက်နှာသို့',
        mode : 'ios',
        animated : animated,
        scrollPadding: false,
        scrollAssist: false
      }
    ),
    AppRoutingModule,
    ShareModule,
    BrowserAnimationsModule,
    RouterModule,
    HttpClientModule
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },{ provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true}],
  bootstrap: [AppComponent],
})
export class AppModule { }
