import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  // {
  //   path: '',
  //   redirectTo: '',
  //   pathMatch: 'full'
  // },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home-routing.module').then( m => m.HomePageRoutingModule)
  },
  {
    path: 'risk-assessment',
    loadChildren: () => import('./pages/risk-assessment/risk-assessment.module').then( m => m.RiskAssessmentPageModule)
  },
  {
    path: 'risk-assessment/intro',
    loadChildren: () => import('./pages/intro/intro.module').then( m => m.IntroPageModule)
  },
  {
    path: 'risk-assessment/intro-surveys',
    loadChildren: () => import('./pages/intro-questions/intro-questions.module').then( m => m.IntroQuestionsPageModule)
  },
  {
    path: 'risk-assessment/surveys',
    loadChildren: () => import('./pages/questions/questions.module').then( m => m.QuestionsPageModule)
  },
  {
    path: 'risk-assessment/result',
    loadChildren: () => import('./pages/result/result.module').then( m => m.ResultPageModule)
  },
  {
    path: 'register-info',
    loadChildren: () => import('./pages/register-info/register-info.module').then( m => m.RegisterInfoPageModule)
  },
  {
    path: 'pretest-info',
    loadChildren: () => import('./pages/pretest-info/pretest-info.module').then( m => m.PretestInfoPageModule)
  },
  {
    path: 'qr-info',
    loadChildren: () => import('./pages/qr-info/qr-info.module').then( m => m.QrInfoPageModule)
  },
  {
    path: 'risk-assessment/survey-summary',
    loadChildren: () => import('./pages/question-summary/question-summary.module').then( m => m.QuestionSummaryPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'registration',
    loadChildren: () => import('./pages/registration/registration.module').then( m => m.RegistrationPageModule)
  },
  {
    path: 'clinic',
    loadChildren: () => import('./pages/clinic/clinic.module').then( m => m.ClinicPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'risk-assessment/information',
    loadChildren: () => import('./pages/before-regular-quesition/before-regular-quesition.module').then( m => m.BeforeRegularQuesitionPageModule)
  },
  {
    path: 'qr-form-login',
    loadChildren: () => import('./pages/qr-form-login/qr-form-login.module').then( m => m.QrFormLoginPageModule)
  },
  {
    path: 'user-form-login',
    loadChildren: () => import('./pages/user-form-login/user-form-login.module').then( m => m.UserFormLoginPageModule)
  },
  {
    path: 'risk-assessment/edit-survey',
    loadChildren: () => import('./pages/question-edit/question-edit.module').then( m => m.QuestionEditPageModule)
  },
  {
    path: 'feedback-modal',
    loadChildren: () => import('./pages/feedback-modal/feedback-modal.module').then( m => m.FeedbackModalPageModule)
  },
  {
    path: 'risk-assessment/agreement',
    loadChildren: () => import('./pages/agreement/agreement.module').then( m => m.AgreementPageModule)
  },
  {
    path: 'tb-registration',
    loadChildren: () => import('./pages/agreement-send-email/agreement-send-email.module').then( m => m.AgreementSendEmailPageModule)
  },
  {
    path: 'tb-acknowledgement',
    loadChildren: () => import('./pages/tb-acknowledgement/tb-acknowledgement.module').then( m => m.TbAcknowledgementPageModule)
  },
  {
    path: 'clinic',
    loadChildren: () => import('./pages/clinic/clinic.module').then( m => m.ClinicPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'risk-assessment/information',
    loadChildren: () => import('./pages/before-regular-quesition/before-regular-quesition.module').then( m => m.BeforeRegularQuesitionPageModule)
  },
  {
    path: 'qr-form-login',
    loadChildren: () => import('./pages/qr-form-login/qr-form-login.module').then( m => m.QrFormLoginPageModule)
  },
  {
    path: 'user-form-login',
    loadChildren: () => import('./pages/user-form-login/user-form-login.module').then( m => m.UserFormLoginPageModule)
  },
  {
    path: 'risk-assessment/edit-survey',
    loadChildren: () => import('./pages/question-edit/question-edit.module').then( m => m.QuestionEditPageModule)
  },
  {
    path: 'feedback-modal',
    loadChildren: () => import('./pages/feedback-modal/feedback-modal.module').then( m => m.FeedbackModalPageModule)
  },
  {
    path: 'risk-assessment/agreement',
    loadChildren: () => import('./pages/agreement/agreement.module').then( m => m.AgreementPageModule)
  },
  {
    path: 'tb-agreement',
    loadChildren: () => import('./pages/tb-agreement/tb-agreement.module').then( m => m.TbAgreementPageModule)
  },
  {
    path: 'hiv-testing',
    loadChildren: () => import('./pages/hiv-testing/hiv-testing.module').then( m => m.HivTestingPageModule)
  },
  {
    path: 'testing-video',
    loadChildren: () => import('./pages/testing-video/testing-video.module').then( m => m.TestingVideoPageModule)
  },
  {
    path: 'usage-steps',
    loadChildren: () => import('./pages/usage-steps/usage-steps.module').then( m => m.UsageStepsPageModule)
  },
  {
    path: 'hiv-self-testing',
    loadChildren: () => import('./pages/hiv-self-testing/hiv-self-testing.module').then( m => m.HivSelfTestingPageModule)
  },
  {
    path: 'self-report',
    loadChildren: () => import('./pages/self-report/self-report.module').then( m => m.SelfReportPageModule)
  },  {
    path: 'self-report-result',
    loadChildren: () => import('./pages/self-report-result/self-report-result.module').then( m => m.SelfReportResultPageModule)
  }




];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
